import { useState,useEffect } from "react";
import { ethers, BigNumber } from "ethers";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
// 合约对应的json文件--合约编译后
import env from "./env.json"
const MainMint = ({ accounts, setAccounts }) => {
  const [mintAmount, setMintAccount] = useState(1);
  const [remain, setRemain] = useState();
  const isConnected = Boolean(accounts[0]);
  async function handleMint() {
    console.log("MainMint");
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();
      //console.log(process.env.REACT_APP_CONTRACT_ADDRESS,env.abi);
      const contract = new ethers.Contract(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        env.abi,
        signer
      );
      
      //console.log(process.env.REACT_APP_CONTRACT_ADDRESS);
      try {
        
        const response = await contract.mintNFT(BigNumber.from(mintAmount), {
          value: ethers.utils.parseEther((process.env.REACT_APP_Mint_Price * mintAmount).toString()),
        });
        console.log("response", response);
        getRemaining()
      } catch (err) {
        console.log("error", err);
      }
    }
  }
  async function getRemaining() {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        env.abi,
        provider
      );
      const response = await contract._numAvailableTokens.call()
      setRemain(response.toString());
      //console.log("response", response);
    }
  }
  useEffect(() => {if (isConnected)getRemaining()},[isConnected])
  const handlerDecrement = () => {
    if (mintAmount <= 1) return;
    setMintAccount(mintAmount - 1);
  };

  const handlerIncrement = () => {
    if (mintAmount >= 3) return;
    setMintAccount(mintAmount + 1);
  };

  return (
    <Flex
      id="main"
      justify="space-evenly"
      align="center"
      height="100vh"
      paddingBottom="100px"
      textAlign={"center"}
    >
      {/* <Image boxSize="25vh" objectFit="cover" src={img} alt="img"></Image>
      <Image boxSize="25vh" objectFit="cover" src={img} alt="img"></Image> */}
      <Box width="520px">
        <Text fontSize="40px" textShadow="0 5px #000000">
          Punk Trend<br></br>
          <br></br>$etters
        </Text>

        {/* <Text
          fontSize="30px"
          letterSpacing="-5.5%"
          fontFamily="VT323"
          textShadow="0 2px 2px #000000"
        >
          It's 2078. Can the RoboPunks NFT save humans from destructive rampnt
          NFT speculation? Mint Robopunks to find out!
        </Text> */}
        {remain ?<Text fontSize="1rem" textShadow="0 5px #000000">{"Amount Remaining:"+remain+"/6336"}</Text>:null}
        {isConnected ? (
          <div>
            <Flex justify="center" align="center">
              <Button
                backgroundColor="red"
                borderRadius="5px"
                boxShadow="0px 2px 2px 1px #0F0F0F"
                color="white"
                cursor="pointer"
                fontFamily="inherit"
                padding="15px"
                marginTop="10px"
                onClick={handlerDecrement}
              >
                -
              </Button>
              <Input
                readOnly
                fontFamily="inherit"
                width="100px"
                height="40px"
                textAlign="center"
                paddingLeft="19px"
                marginTop="10px"
                type="number"
                value={mintAmount}
              />
              <Button
                backgroundColor="red"
                borderRadius="5px"
                boxShadow="0px 2px 2px 1px #0F0F0F"
                color="white"
                cursor="pointer"
                fontFamily="inherit"
                padding="15px"
                marginTop="10px"
                onClick={handlerIncrement}
              >
                +
              </Button>
            </Flex>
            <Button
              backgroundColor="red"
              borderRadius="5px"
              boxShadow="0px 2px 2px 1px #0F0F0F"
              color="white"
              cursor="pointer"
              fontFamily="inherit"
              padding="15px"
              marginTop="10px"
              onClick={handleMint}
            >
              Mint Now
            </Button>
          </div>
        ) : (
          <Text
            marginTop="70px"
            fontSize="30px"
            letterSpacing="-5.5%"
            fontFamily="VT323"
            textShadow="0 3px #000000"
            color="red"
          >
            You must be connected to Mint.
          </Text>
        )}
        
      </Box>
      {/* <Image boxSize="25vh" objectFit="cover" src={img} alt="img"></Image>
      <Image boxSize="25vh" objectFit="cover" src={img} alt="img"></Image> */}
    </Flex>
  );
};

export default MainMint;
